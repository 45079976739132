<template>
  <div class="">
    <div id="components-form-demo-advanced-search">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
<!--          <a-col :span="6">-->
<!--            <a-form-item label="单位名称：">-->
<!--              <a-input placeholder="请输入单位名称进行查询"/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :span="6">
            <a-form-item label="姓名：">
              <a-input placeholder="请输入姓名进行查询" v-model="param.name"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="手机号：">
              <a-input :maxLength = "11" placeholder="请输入手机号进行查询" v-model="param.contact"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="所属组织：">
              <a-select :value="param.org" placeholder="请选择" @change="handleChange">
                <a-select-option :value="item.dbId" v-for="(item,index) in deptList" :key="index">
                  {{ item.dept_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="所属角色：">
              <a-select :value="param.role" placeholder="请选择" @change="handleChange2">
                <a-select-option :value="item.dbId" v-for="(item,index) in roleList" :key="index">
                  {{ item.role_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" style="margin-top:20px">
            <a-form-item label="创建时间：">
              <a-range-picker :value="pickerValue" @change="onChange" :placeholder="['开始时间','结束时间']"/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">
              查询
            </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              清除
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <div class="table">
        <a-row>
          <a-col :span="24" :style="{ textAlign: 'left' }">
            <a-button type="primary" html-type="submit" @click="toCreate()">
              新建
            </a-button>
          </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" style="margin-top:20px">
          <a slot="name" slot-scope="text">{{ text }}</a>
          <span slot="status" slot-scope="text,record">
            <a-switch slot="status" :default-checked="record.status == 0" @change="onChange2($event,record)"/>
          </span>
          <template slot="opera" slot-scope="text,record">
            <a-tag slot="opera" color="green" @click="toShow(record)"  style="cursor: pointer;">
              查看
            </a-tag>
            <a-tag slot="opera" color="green" @click="toEdit(record)"  style="cursor: pointer;">
              编辑
            </a-tag>
            <a-tag slot="opera" color="volcano" @click="deleteData(record)"  style="cursor: pointer;">
              删除
            </a-tag>
          </template>
        </a-table>
      </div>
    </div>

    <!-- 删除弹框 -->
    <div>
      <a-modal
      title="提示"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      ok-text="确认" cancel-text="取消"
    >
      <p>{{ ModalText }}</p>
    </a-modal>
    </div>
  </div>
</template>
<script>
import { getUserList, deleteUser, changeStatus } from '@/api/permission/user/index'
import { getDeptList } from "@/api/permission/organization/index";
import { getRoleList } from "@/api/permission/role/index";
const columns = [
  {
    title: "序号 ",
    dataIndex: "no",
    key: "no"
  },
  {
    title: "姓名 ",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "联系方式 ",
    dataIndex: "tel",
    key: "tel"
  },
  {
    title: "所属组织 ",
    key: "org",
    dataIndex: "org"
  },
  {
    title: "角色",
    key: "role",
    dataIndex: "role"
  },
  {
    title: "状态 ",
    key: "status",
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "创建时间 ",
    key: "time",
    dataIndex: "time"
  },
  {
    title: "操作 ",
    key: "opera",
    dataIndex: "opera",
    scopedSlots: { customRender: "opera" }
  }
];

const data = [
  // {
  //   key: "1",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "2",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "3",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "4",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "5",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "6",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "7",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "8",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "9",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "10",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "11",
  //   name: "John Brown",
  //   no: 32,
  //   tel: "17327890909",
  //   org: "1",
  //   role: "John Brown",
  //   status: 2,
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // }
];

export default {
  data() {
    return {
      form: this.$form.createForm(this, {
        name: "" ,
      }),
      param:{
        name: "" ,
        contact:"",
        role:undefined,
        org:undefined,
      },
      data,
      columns,
      ModalText: '确定要删除该用户吗？',
      visible: false,
      confirmLoading: false,

      pickerValue: [],

      userName: "",
      beginTime: "",
      endTime: "",
      id: 0,
      deptList:[],
      roleList: [],
    };
  },
  computed: {
  },
  created() {
    this.getList({});
    this.getOrganizations();
    this.getRoles();
  },
  updated() {
    console.log("updated");
  },
  methods: {
    getList(data) {
      console.log(data);
      this.data = [];
      getUserList(data).then(res => {
        console.log(res);
        for (let i in res.rows) {
          let data = res.rows[i];
          this.data.push({
            key: i,
            no: parseInt(i) + 1,
            id: data.dbId,
            name: data.nick_name,
            tel: data.phonenumber,
            org: data.dept.dept_name || "",
            role: data.roles[0]?.role_name,
            status:  data.status,
            time: data.create_time,
            opera: ["查看", "编辑", "删除"]
          });
        }
        console.log(this.data);
      });
    },
    getOrganizations(){
      this.deptList = [];
      getDeptList({

      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.deptList = res.data;
        }
      })
    },
    getRoles(){
      this.roleList = [];
      getRoleList({

      }).then(res => {
        if (res.code == 200) {
          this.roleList = res.rows;
        }
      })
    },
    // 搜索
    handleSearch(e) {

      e.preventDefault();
      // if(this.param.contact&&!(/^1[3456789]\d{9}$/.test(this.param.contact))){
      //   this.$message.error("请输入正确的手机号");
      //   return
      // }
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
      let data = "";
      data = "nick_name=" + this.param.name +
              "&phonenumber=" + this.param.contact;
      if (this.param.org != undefined) {
          data += "&dept_id=" + this.param.org;
      }
      if (this.param.role != undefined) {
        data += "&roleIds=" + this.param.role;
      }
     data += "&begin_time=" + this.beginTime +
              "&end_time=" + this.endTime;
      this.getList(data);
    },
    handleChange(value){
        console.log(value)
      this.param.org = value;
    },
    handleChange2(value){
      this.param.role = value;
    },
    // 日期选择框
    onChange(date, dateString) {
      console.log(date, dateString);
      this.pickerValue = date;
      this.beginTime = dateString[0];
      this.endTime = dateString[1];
    },
    //清除
    handleReset() {
      this.pickerValue = [];
      this.form.resetFields();
      this.beginTime = "";
      this.endTime = "";
      this.param.name = "";
      this.param.contact = "";
      this.param.org = undefined;
      this.param.role = undefined;
      this.getList();
    },
    //打开删除弹框
    deleteData(value) {
      console.log(value)
      this.id = value.id;
      this.visible = true;
    },
    //确定删除
    handleOk() {
      this.visible = false;
      let _this = this;
      deleteUser({
        userIds: _this.id
      }).then(res => {
        console.log(res);
        _this.$message.success("删除成功", function() {
          _this.getList();
        });
      }).catch(err => {
          _this.$message.error(err.message);
      });
    },
    //取消删除
    handleCancel() {
      this.visible = false;
    },
    //新建
    toCreate() {
      this.$router.push({
        path: "/index/auth/users/createUsers"
      });
    },
    //新建/编辑/查看
    toEdit(value) {
      console.log(value);
      this.id = value.id;
      this.$router.push({
        path: "/index/auth/users/createUsers",
        query: {
          id: this.id
        }
      });
    }, //新建/编辑/查看
    toShow(value) {
      this.id = value.id;
      this.$router.push({
        path: "/index/auth/users/createUsers",
        query: {
          id: this.id,
          show: 1
        }
      });
    },
    onChange2(e,p) {
      console.log(e);
      console.log(p);
      let status = e ? 0 : 1;
      let _this = this;
      changeStatus({
        dbId:p.id,
        status: status
      }).then(res => {
        if(res.code == 200) {
          _this.$message.success('状态更新成功', function () {
            // _this.getList();
          })
        } else {
          _this.$message.error('状态更新失败');
        }
      })
    },
  }
};
</script>
<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #ffffff;
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 6px; */
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}
#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #ffffff;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.table {
  margin-top: 20px;
  background: #ffffff;
  padding: 20px;

}
</style>
